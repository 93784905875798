import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FAQsContent from '../../components/FAQs/FAQs'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { FAQS, FAQS_CONTESTS } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'FAQs',
    url: FAQS,
  },
  {
    title: 'Contests',
    url: FAQS_CONTESTS,
  },
]

const FAQs: React.FC = () => {
  const [contestsFaqsSt, setContestsFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ contestsFaqs }) =>
      setContestsFaqsSt(contestsFaqs)
    )
  }, [])
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Contests FAQ: Read Frequently Asked Questions on Contests in Games"
        description="Read frequently asked questions on casual games in Mega. Find answers to queries on various contests, buy-in, winnings, and rules. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(contestsFaqsSt)}
      />
      <FAQsContent title="Mega Contests" index={4} faqs={contestsFaqsSt} />
    </Layout>
  )
}

export default FAQs
